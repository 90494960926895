import { HttpHeader } from '@solidjs/start';
import { Button, Link, Container, Heading, Section } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { getRequestEvent, isServer } from 'solid-js/web';
import { getConfigValue } from '../modules/config';

const params = new URLSearchParams({
	width: '480',
	height: '300',
	format: 'webp',
	mode: 'crop',
	crop: 'entropy',
}).toString();

export function NotFoundContent() {
	// Server hack to force status to a 404, otherwise it is a 200 as a "soft" 404
	if (isServer) {
		const event = getRequestEvent();
		if (event) {
			event.response.status = 404;
		}
	}

	return (
		<Container size="small">
			<Title>404 not found | Troon</Title>
			<HttpHeader name="status" value="404" />
			<Section
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class="min-h-[50svh] items-center justify-center text-center"
			>
				<Heading as="h1" class="flex flex-col">
					<span
						class="bg-cover bg-clip-text bg-center font-bold leading-none text-transparent"
						style={{
							'background-image': `url(${getConfigValue('IMAGE_HOST')}/digital/hero/404.png?${params})`,
							'font-size': 'clamp(min(3vw, 10rem), 18vw, 18rem)',
						}}
					>
						404
					</span>
					Page Not Found
				</Heading>

				<p>We are sorry, but we can’t seem to find the page you are looking for.</p>

				<Button as={Link} href="/" class="size-fit shrink grow-0">
					Return home
				</Button>
			</Section>
		</Container>
	);
}
